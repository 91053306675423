import { createStyles, FormControl, makeStyles, NativeSelect, Theme } from "@material-ui/core";
import { ICustomerCount } from "../../../../../@types/interfaces/customerCount.interface";
import PieChart from "../../../../shared/charts/piechart/PieChart";
import "./CustomerDetails.css";

const CustomerDetails = ({ data }: { data: ICustomerCount }) => {
	return (
		<div className="admin-pie-conatiner">
			<div className="admin-heading-title">
				<p>Total Customers Count</p>
				<h2>{data.totalCustomerCount}</h2>
			</div>
			<div className="admin-container-body">
				<div className="admin-left-data">
					<div className="admin-stat-data">
						<p>Registered Customer</p>
						<h2>{data.registeredCustomersCount}</h2>
					</div>
					<div className="admin-stat-data">
						<p>Unregistered Customer</p>
						<h2>{data.unRegisteredCustomersCount}</h2>
					</div>
				</div>
				<div className="admin-chart-container">
					<PieChart
						registeredCustomersCount={data.registeredCustomersCount}
						totalCustomerCount={data.totalCustomerCount}
						unRegisteredCustomersCount={data.unRegisteredCustomersCount}
						key="pie_customer_data"
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomerDetails;
