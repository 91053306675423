import React from "react";
import { defaultImage, footProfile } from "../../../../../../../../assets";
import { Grid } from "@material-ui/core";
import KnowMoreButton from "../knowMoreButton/KnowMoreButton";
import RightfeetProfile from "../../../../../../../shared/rightFeetProfile/RightfeetProfile";
import { IDiagnosis } from "../../../../../../../../@types/diagnosis.interface";
import ConnectedList from "../../../../../../../shared/connectedList/ConnectedList";

const RightPainPointDetails = ({ footcareAnalysis }: { footcareAnalysis: IDiagnosis }) => {
	return (
		<div className="rightPainPointDetails painpointDetailsDivision">
			<h2 style={{ marginBottom: 12 }}>Right Feet</h2>
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<RightfeetProfile footcareAnalysis={footcareAnalysis} key="right_foor_pain" />
				</Grid>
				<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
					<div className="footDisease">
						<h2 style={{ marginBottom: 18 }}>Detected Disease</h2>
						<ConnectedList items={footcareAnalysis?.right_feet_diagnosis_name_list} key="left_feet_name" />
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default RightPainPointDetails;
