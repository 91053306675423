import { Grid } from "@material-ui/core";
import React from "react";
import ImageAnalysisContainer from "./ImageAnalysisContainer/ImageAnalysisContainer";
import { leftFeetImage, leftSideFeet, rightFeetImage, rightSideFeet, sideFeet } from "../../../../../../../assets";
import { ILegScanProps } from "../../../../../../../@types/components/legScanDetailsprops.types";
import { roundToTwoDecimals } from "../../../../../../../utils/commonFunctions/formatNumber";

const FeetImageAnalysis = ({ legScan, state }: ILegScanProps) => {
	return (
		<Grid container spacing={10}>
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
				<ImageAnalysisContainer
					title="Left Feet"
					sideImage={leftSideFeet}
					topImage={leftFeetImage}
					feet_arc_height={roundToTwoDecimals(legScan.left_arc_height)}
					feet_heel_width={roundToTwoDecimals(legScan.left_heel_width)}
					feet_instep_height={roundToTwoDecimals(legScan.left_instep_height)}
					feet_length={roundToTwoDecimals(legScan.left_length)}
					feet_width={roundToTwoDecimals(legScan.left_width)}
					arc_type={legScan.arch_type_left}
					key={0}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={6}
				lg={6}
				xl={6}
				style={state === "REPORT" ? { marginTop: 180 } : { marginTop: 0 }}
			>
				<ImageAnalysisContainer
					title="Right Feet"
					sideImage={rightSideFeet}
					topImage={rightFeetImage}
					feet_arc_height={roundToTwoDecimals(legScan.right_arc_height)}
					feet_heel_width={roundToTwoDecimals(legScan.right_heel_width)}
					feet_instep_height={roundToTwoDecimals(legScan.right_instep_height)}
					feet_length={roundToTwoDecimals(legScan.right_length)}
					feet_width={roundToTwoDecimals(legScan.right_width)}
					arc_type={legScan.arch_type_right}
					key={1}
				/>
			</Grid>
		</Grid>
	);
};

export default FeetImageAnalysis;
