import { Grid, Paper } from "@material-ui/core";
import "./PainPointDetails.css";
import LeftPainPointDetails from "./leftPainPointDetails/LeftPainPointDetails";
import RightPainPointDetails from "./rightPainPointDetails/RightPainPointDetails";
import { IDiagnosis } from "../../../../../../../@types/diagnosis.interface";

const PainPointDetails = ({ footcareAnalysis }: { footcareAnalysis: IDiagnosis }) => {
	return (
		<Paper className="painPointDetails" style={{ marginBottom: "2.5rem", paddingTop: "12px" }}>
			<h2>Pain Points and Disease</h2>
			<Grid container spacing={2} style={{ padding: "1rem" }}>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<LeftPainPointDetails footcareAnalysis={footcareAnalysis} />
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<RightPainPointDetails footcareAnalysis={footcareAnalysis} />
				</Grid>
			</Grid>
		</Paper>
	);
};

export default PainPointDetails;
