import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useState, useEffect } from "react";
import useQuerySearch from "../../../hooks/querySearch/useQuerySearch";

const DownloadReport = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const qs = useQuerySearch("qs");

	const waitForAPICalls = async (iframe: HTMLIFrameElement) => {
		return new Promise<void>((resolve: any) => {
			const observer = new PerformanceObserver((list, obs) => {
				const entries = list.getEntries().filter((entry: any) => {
					const resourceEntry = entry as PerformanceResourceTiming;
					return resourceEntry.initiatorType === "xmlhttprequest" || resourceEntry.initiatorType === "fetch";
				});
				if (entries.length === 0) {
					obs.disconnect();
					resolve();
				}
			});
			observer.observe({ type: "resource", buffered: true });

			// Timeout as a fallback in case no API calls are detected
			setTimeout(() => {
				observer.disconnect();
				resolve();
			}, 15000); // Fallback timeout of 15 seconds
		});
	};

	const printPageLink = async () => {
		setLoading(true);
		const pageLink = `https://admin.curafoot.ai/reports?qs=${qs}`;
		const iframe = document.createElement("iframe");
		iframe.style.display = "none";
		iframe.src = pageLink;

		iframe.onload = async () => {
			await waitForAPICalls(iframe); // Wait for API calls to complete
			setLoading(false);
			iframe.contentWindow?.print();
		};

		document.body.appendChild(iframe);
	};

	return (
		<div>
			<Button onClick={printPageLink} variant="contained" className="dark-button" endIcon={<GetAppIcon />}>
				{loading ? "Generating..." : "Generate Report"}
			</Button>
		</div>
	);
};

export default DownloadReport;
