import GridCard from "./gridCard/GridCard";
import "./CustomerScanDetails.css";
import ReportQrCode from "./reportQrCode/ReportQrCode";
import { Grid, Paper } from "@material-ui/core";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import FeetImages from "./FeetImages/FeetImages";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import FeetImageAnalysis from "./FeetImageAnalysis/FeetImageAnalysis";
import ScanDetailsWithQrCode from "./scanDetailsWithQrCode/ScanDetailsWithQrCode";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../../../../../utils/api";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "../../../../../../hooks";
import { ICustomerLegScanType } from "../../../../../../@types/customerLegScan.types";
import { AuthContext } from "../../../../../../contexts";
import CustomerDetailCard from "./customerDetailsCrad/CustomerDetailCard";
import NotFound from "../../../../../shared/notFound/NotFound";

const CustomerScanDetails = ({ state }: { state: "REPORT" | "ROUTE" }) => {
	const { user } = useContext(AuthContext);
	const [scanObejctId, setScanObjectId] = useState<string | null>(null);
	const [legScanDetails, setLegScanDetail] = useState<ICustomerLegScanType | null>(null);
	const query = useQuery();
	const getCustomerScanDetails = useCallback(async () => {
		if (scanObejctId) {
			const legScan = await trackPromise(api.scan.getScanDetailsByScanObjectId({ scan_object_id: scanObejctId }));
			setLegScanDetail(legScan);
		}
	}, [scanObejctId]);

	useEffect(() => {
		if (query && query.get("qs")) {
			setScanObjectId(query.get("qs"));
		}
		getCustomerScanDetails();
	}, [getCustomerScanDetails, query]);

	return (
		<div className="customerScanDetails" id="customerScanDetails">
			{state !== "REPORT" ? (
				legScanDetails ? (
					<ScanDetailsWithQrCode legScan={legScanDetails} state={state} />
				) : (
					<NotFound />
				)
			) : null}

			{state === "REPORT" && legScanDetails ? (
				<CustomerDetailCard legScan={legScanDetails} state="REPORT" key={1} />
			) : null}

			{state !== "REPORT" ? (
				<Paper elevation={2} style={{ borderRadius: "15px", padding: "1rem", marginTop: "2rem" }}>
					<div className="images-section">
						<h2 className="image-heading">
							<PhotoAlbumIcon style={{ color: "#25a18e", fontSize: "1.6rem" }} />
							<span>Feet Images</span>
						</h2>
						<div className="allImages">
							{legScanDetails ? <FeetImages legScan={legScanDetails} state={state} /> : null}
						</div>
					</div>
				</Paper>
			) : null}

			<Paper
				elevation={2}
				style={{ borderRadius: "15px", padding: "1rem", marginTop: state === "REPORT" ? "1rem" : "2rem" }}
			>
				<div className="images-section">
					<h2 className="image-heading">
						<TrackChangesIcon style={{ color: "#25a18e", fontSize: "1.6rem" }} />
						<span>Feet Image Analysis</span>
					</h2>
					<div className="allImages">
						{legScanDetails ? <FeetImageAnalysis legScan={legScanDetails} state={state} /> : null}
					</div>
				</div>
			</Paper>
		</div>
	);
};

export default CustomerScanDetails;
