/* eslint-disable prettier/prettier */
/* eslint-disable arrow-parens */
import { useState, useCallback, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../constants/caption/dashboardHeader";
import CustomPagination from "../../../../shared/customPagination/CustomPagination1";
import { UIContext, AuthContext } from "../../../../../contexts";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";

import { Button, useMediaQuery } from "@material-ui/core";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { PaginationQuery, PaginationTypes } from "../../../../../@types/pagination.types";
import getLimit from "../../../../../utils/pagination/getLimit";
import ROLES from "../../../../../constants/roles";
import initCapitalize from "../../../../../utils/commonFunctions/initCapitalize";
// import { api } from "../../../../utils/api";
import useRouteParam from "../../../../../hooks/useRouteParam";
// import { AssignedPaginatedcustomers } from "../../../../@types/api/admin/user/user.types";
// import { customer } from "../../../../@types/customer.types";
import { dateTimeZoneConverter } from "../../../../../utils/commonFunctions/date";
import { trackPromise } from "react-promise-tracker";
import { handleValueCellRenderer } from "../../../../../utils/commonFunctions/cellRenderers";
import { CustomFloatingFilter } from "../../../../shared";
import { CustomFilter } from "../../../../shared";
// import { UIFormatSSN } from "../../../../utils/commonFunctions/SSN";
// import { GROUP_NUMBER } from "../../../../constants/groups";

import "./registeredCustomerScanDetails.css";
import { api } from "../../../../../utils/api";
import { useQuery } from "../../../../../hooks";
import useQuerySearch from "../../../../../hooks/querySearch/useQuerySearch";

const RegisteredCustomerScanDetails = (): JSX.Element => {
	const { customer } = useContext(UIContext);
	const group_number = useRouteParam("gn");
	const query = useQuery();
	const [customers, setcustomers] = useState<any[]>([]);
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);
	const { setDashboardHeader } = useContext(UIContext);
	const navigate = useNavigate();
	const [limit, setLimit] = useState<number | null>(null);
	const [customerObjectid, setCustomerObjectId] = useState<string | null>(null);
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const [groupcustomersFilterSchema, setGroupcustomersFilterSchema] = useState({
		customer_id: {
			value: null,
			type: null
		},
		first_name: {
			value: null,
			type: null
		},
		last_name: {
			value: null,
			type: null
		},
		ssn: {
			value: null,
			type: null
		},
		role: {
			value: null,
			type: null
		}
	});
	const { currentPage, pageCount } = pagination;
	const xl = useMediaQuery("(min-width:1600px)");
	const lg = useMediaQuery("(min-width:1200px)");
	const md = useMediaQuery("(min-width:800px)");
	const sm = useMediaQuery("(min-width:600px)");
	const xs = useMediaQuery("(min-width:400px)");
	const _limit = getLimit(xl, lg, md, sm, xs);
	const queryGroupNumber = useRouteParam("gn");

	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const handleViewcustomersDetails = () => {
		const focusedCell = agGridRef.current.api.getFocusedCell();
		const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);

		const { data } = row;

		navigate(`/admin/customer-details?gn=${group_number}&qs=${data._id}`);

		// if (role === ROLES.employee) navigate(`/employer/customer?gn=${group_number}&&qs=${encryption(SSN) as string}`);
		// else if (role === ROLES.dependent)
		// 	navigate(`/employer/customer/dependent?gn=${group_number}&&qs=${encryption(SSN) as string}`);
	};

	const handleActionDetailsButtonRender = () => {
		return (
			<Button
				className="ag-grid-cell-button"
				variant="outlined"
				onClick={handleViewcustomersDetails}
				style={{ marginTop: "3px", marginBottom: "6px" }}
			>
				See Details
			</Button>
		);
	};

	// const handleActionHistoryButtonRender = useCallback(() => {
	// 	return (
	// 		<Button className="ag-grid-cell-button button-black" variant="outlined" onClick={handleNewPageChange}>
	// 			Move to History
	// 		</Button>
	// 	);
	// }, [handleNewPageChange]);

	const getPaginatedCustomerScanList = useCallback(async () => {
		if (typeof limit === "number" && typeof pagination.currentPage === "number") {
			const paginationQuery: PaginationQuery = {
				limit: limit,
				page: pagination.currentPage
			};
			const customerScanListResponse = await trackPromise(
				api.customer.getPaginatedScanList({
					...paginationQuery,
					group_number: group_number,
					customer_object_id: customerObjectid
				})
			);
			setcustomers(customerScanListResponse.scans);
			setPagination(
				Object.assign({}, pagination, {
					pageCount: customerScanListResponse.totalPages
				})
			);
		}
	}, [limit, pagination.currentPage, group_number, customerObjectid]);

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: page
				})
			);
		},
		[pagination]
	);

	const handleDateCellRenderer = ({ value }: { value: string }) => dateTimeZoneConverter(value);

	const handleSSNCellRenderer = ({ value }: { value: string }) => {
		const SSN = value.substring(0, 3) + "-" + value.substring(3, 5) + "-" + value.substring(5);
	};

	const handleUserRoleCellRenderer = ({ value }: { value: string }) => {
		const role = initCapitalize(value as string);
		return <div>{role}</div>;
	};

	const handleRegisteredcustomerCellRenderer = ({ value }: { value: boolean }) => {
		return <div>{value ? "Yes" : "No"}</div>;
	};

	// const getcustomerCountOfParticularGroup = useCallback(async () => {
	// 	if (limit) {
	// 		const customerCount = await api.user.getcustomerCountOfParticularGroup(queryGroupNumber as number);
	// 		const pageCount = Math.ceil(customerCount / limit);
	// 		console.log("pageCount", pageCount);
	// 		setPagination(Object.assign({}, pagination, { pageCount }));
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [limit]);

	// const getPaginatedcustomersByGroupAndAssignedLocation = useCallback(async () => {
	// 	if (typeof limit === "number" && typeof pagination.currentPage === "number") {
	// 		let paginationQuery: PaginationQuery = {
	// 			limit: limit,
	// 			page: pagination.currentPage
	// 		};
	// 		Object.entries(groupcustomersFilterSchema).map(([key, value]) => {
	// 			if (value.value !== "" && value.value !== null) {
	// 				paginationQuery = {
	// 					...paginationQuery,
	// 					[key]: key === "role" ? (value.value as string).toUpperCase() : value.value
	// 				};
	// 			}
	// 		});
	// 		const assignedPaginatedcustomers: AssignedPaginatedcustomers = await trackPromise(
	// 			api.user.getPaginatedcustomersByGroupAndAssignedLocation(paginationQuery, queryGroupNumber as number)
	// 		);
	// 		setcustomers(Object.assign([], assignedPaginatedcustomers.customers));
	// 	}
	// 	// const paginationQuery: PaginationQuery = {
	// 	// 	limit: 30000,
	// 	// 	page: 1
	// 	// };
	// 	// const assignedPaginatedcustomers: AssignedPaginatedcustomers = await trackPromise(
	// 	// 	api.user.getPaginatedcustomersByGroupAndAssignedLocation(paginationQuery, queryGroupNumber as number)
	// 	// );
	// 	// setcustomers(Object.assign([], assignedPaginatedcustomers.customers));
	// }, [limit, pagination.currentPage, queryGroupNumber, groupcustomersFilterSchema]);

	// useEffect(() => {
	// 	setLimit(_limit);
	// 	setDashboardHeader(ADMIN_DASHBOARD_HEADER.customers);
	// }, [_limit, getcustomerCountOfParticularGroup, setDashboardHeader]);

	useEffect(() => {
		if (_limit >= 0) {
			setLimit(_limit);
			getPaginatedCustomerScanList();
		}
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.registered_customer_scan_details);
	}, [_limit, setDashboardHeader, getPaginatedCustomerScanList]);

	useEffect(() => {
		if (query && query.get("qs")) {
			setCustomerObjectId(query.get("qs"));
		}
	}, [query]);

	return (
		<div className="all-customer-page-container">
			<div className="all-customers-grid-container">
				<div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
					<AgGridReact
						ref={agGridRef}
						rowData={customers}
						onGridReady={handleAgGridReady}
						onFirstDataRendered={handleFirstDataRendered}
						rowSelection={"Single"}
						animateRows
						suppressColumnVirtualisation={true}
						defaultColDef={{
							filter: "agTextColumnFilter",
							floatingFilter: true,
							resizable: true
						}}
						enableCellChangeFlash={true}
						defaultColGroupDef={{ marryChildren: true }}
						frameworkComponents={{
							handleActionDetailsButtonRender: handleActionDetailsButtonRender,
							handleDateCellRenderer: handleDateCellRenderer,
							handleSSNCellRenderer: handleSSNCellRenderer,
							handleUserRoleCellRenderer: handleUserRoleCellRenderer,
							handleRegisteredcustomerCellRenderer: handleRegisteredcustomerCellRenderer,
							handleValueCellRenderer: handleValueCellRenderer,
							customFloatingFilter: CustomFloatingFilter,
							customFilter: CustomFilter,
							schema: groupcustomersFilterSchema,
							setPayloadSchema: setGroupcustomersFilterSchema,
							setParentSchema: setcustomers
						}}
					>
						<AgGridColumn
							field="customer_id"
							headerName="Customer Id"
							// checkboxSelection={true}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleValueCellRenderer"
							// floatingFilterComponentParams={{
							// 	suppressFilterButton: true
							// }}
							onCellClicked={handleViewcustomersDetails}
							suppressSizeToFit
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
						></AgGridColumn>
						<AgGridColumn
							field="group.group_number"
							headerName="Group Number"
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="group.group_name"
							headerName="Group Name"
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="store.store_number"
							headerName="Store Number"
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="store.store_name"
							headerName="Store Name"
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="scan_id"
							headerName="Scan Id"
							// checkboxSelection={true}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleValueCellRenderer"
							// floatingFilterComponentParams={{
							// 	suppressFilterButton: true
							// }}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
						></AgGridColumn>
						<AgGridColumn
							field="device_id"
							headerName="Device Id"
							// checkboxSelection={true}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleValueCellRenderer"
							// floatingFilterComponentParams={{
							// 	suppressFilterButton: true
							// }}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
						></AgGridColumn>
						<AgGridColumn
							field="customer.first_name"
							headerName="First Name"
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							// onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// floatingFilterComponentParams={{
							// 	suppressFilterButton: true
							// }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
						></AgGridColumn>
						<AgGridColumn
							field="customer.last_name"
							headerName="Last name"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							floatingFilterComponent="customFloatingFilter"
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
							filter="customFilter"
						></AgGridColumn>
						<AgGridColumn
							field="date"
							headerName="Date Of Scan"
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleDateCellRenderer"
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="customer.phone"
							headerName="Phone"
							suppressSizeToFit
							onCellClicked={handleViewcustomersDetails}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleSSNCellRenderer"
							// floatingFilterComponent="customFloatingFilter"
							// filter="customFilter"
							// onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer", textAlign: "left" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="action"
							headerName="See Details"
							cellRenderer="handleActionDetailsButtonRender"
							suppressSizeToFit={true}
							filter={false}
						></AgGridColumn>
					</AgGridReact>
				</div>
			</div>
			<div className="custom-pagination" id="custom-pagination">
				<CustomPagination pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
			</div>
		</div>
	);
};

export default RegisteredCustomerScanDetails;
