import { useState, useCallback, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import getAgGridHeight from "../../../../utils/commonFunctions/getAgGridHeight";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { PaginationQuery, PaginationTypes } from "../../../../@types/pagination.types";
import initCapitalize from "../../../../utils/commonFunctions/initCapitalize";
import { CustomPagination } from "../../../shared";
import { UIContext, AuthContext } from "../../../../contexts";
import { Pagination } from "@material-ui/lab";
import { Button, IconButton, Tooltip, useMediaQuery } from "@material-ui/core";

import { ADMIN_DASHBOARD_HEADER } from "../../../../constants/caption/dashboardHeader";

import DeleteIcon from "@material-ui/icons/Delete";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./customers.css";

import getLimit from "../../../../utils/pagination/getLimit";
import ROLES from "../../../../constants/roles";
import encryption from "../../../../utils/commonFunctions/encryption";
import { dateTimeZoneConverter } from "../../../../utils/commonFunctions/date";
import { trackPromise } from "react-promise-tracker";
import { handleValueCellRenderer } from "../../../../utils/commonFunctions/cellRenderers";
import { CustomFloatingFilter } from "../../../shared";
import { CustomFilter } from "../../../shared";
import { api } from "../../../../utils/api";

const Customers = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const navigate = useNavigate();
	const [customers, setCustomers] = useState<any[]>([]);
	const [gridHeight, setGridHeight] = useState("400px");
	const [customersFilterSchema, setcustomersFilterSchema] = useState({
		first_name: { value: null, type: null },
		last_name: { value: null, type: null },
		date_of_birth: { value: null, type: null },
		ssn: { value: null, type: null },
		role: { value: null, type: null },
		group_name: { value: null, type: null },
		group_number: { value: null, type: null },
		location: { value: null, type: null }
	});
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	// eslint-disable-next-line no-unused-vars
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);
	const [limit, setLimit] = useState<number | null>(null);
	const [pagination, setPagination] = useState<PaginationTypes>({
		currentPage: 1,
		pageCount: undefined
	});
	const { currentPage, pageCount } = pagination;
	const { user } = useContext(AuthContext);
	const xl = useMediaQuery("(min-width:1600px)");
	const lg = useMediaQuery("(min-width:1200px)");
	const md = useMediaQuery("(min-width:800px)");
	const sm = useMediaQuery("(min-width:600px)");
	const xs = useMediaQuery("(min-width:400px)");
	const _limit = getLimit(xl, lg, md, sm, xs);

	const handleAgGridReady = (params: GridReadyEvent) => {
		// eslint-disable-next-line no-unused-vars
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const handleViewcustomersDetails = () => {
		const focusedCell = agGridRef.current.api.getFocusedCell();
		const row = agGridRef.current.api.getDisplayedRowAtIndex(focusedCell.rowIndex);
		// console.log("Row", row);
		const {
			data: { role, group_number, phone }
		} = row;
		navigate(`/admin/registered-customer-scan-details?gn=${group_number}`);
	};

	const handleDetailsChangeRender = () => {
		console.log("");
		return (
			<Button
				className="ag-grid-cell-button"
				variant="outlined"
				onClick={handleViewcustomersDetails}
				style={{ marginTop: "3px", marginBottom: "6px" }}
			>
				See Details
			</Button>
		);
	};

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, page: number) => {
			setPagination(
				Object.assign({}, pagination, {
					currentPage: page
				})
			);
		},
		[pagination]
	);

	const handleDateCellRenderer = ({ value }: { value: string }) => dateTimeZoneConverter(value);

	const handleUserRoleCellRenderer = ({ value }: { value: string }) => {
		const role = initCapitalize(value);
		return <div>{role}</div>;
	};

	const handleRegisteredcustomerCellRenderer = ({ value }: { value: boolean }) => {
		return <div>{value ? "Yes" : "No"}</div>;
	};

	const getPaginatedCustomerList = useCallback(async () => {
		if (typeof limit === "number" && typeof pagination.currentPage === "number") {
			let filterQuery = {};
			const paginationQuery: PaginationQuery = {
				limit: limit,
				page: pagination.currentPage
			};
			if (user?.role === ROLES.admin) {
				filterQuery = { ...paginationQuery, group_number: user.group_number };
			} else {
				filterQuery = { ...paginationQuery };
			}
			console.log("=====>filter query", filterQuery);
			const customerList = await trackPromise(api.customer.getPaginatedCustomerList(paginationQuery));
			setCustomers(customerList.customers);
		}
	}, [limit, pagination.currentPage]);

	// const getcustomersCount = useCallback(async () => {
	// 	if (limit) {
	// 		const customerCount = await api.user.getcustomerCount();
	// 		const pageCount = Math.ceil(customerCount / limit);
	// 		console.log("pageCount", pageCount);
	// 		setPagination(Object.assign({}, pagination, { pageCount }));
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [limit]);

	useEffect(() => {
		if (_limit >= 0) {
			setLimit(_limit);
			getPaginatedCustomerList();
		}
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.all_customers);
	}, [_limit, setDashboardHeader, getPaginatedCustomerList]);

	// useEffect(() => {
	// 	if (limit && pagination.pageCount) {
	// 		getcustomers();
	// 	}
	// }, [pagination, limit, getcustomers]);

	// useEffect(() => {
	// 	getcustomers();
	// 	setDashboardHeader(ADMIN_DASHBOARD_HEADER.all_group_customers);
	// }, [getcustomers, setDashboardHeader]);

	useEffect(() => {
		const _gridHeight = getAgGridHeight(customers.length);
		setGridHeight(_gridHeight);
	}, [customers.length]);

	// console.log("limit", limit);
	// console.log("pagination", pagination);

	return (
		<div className="employee-dependent-container">
			<div className="employee-dependent-grid-container">
				<div className="ag-theme-alpine" style={{ height: gridHeight, width: "100%" }}>
					<AgGridReact
						ref={agGridRef}
						rowData={customers}
						onGridReady={handleAgGridReady}
						onFirstDataRendered={handleFirstDataRendered}
						animateRows
						paginationPageSize={6}
						defaultColDef={{
							filter: "agTextColumnFilter",
							floatingFilter: true,
							resizable: true
						}}
						enableCellChangeFlash={true}
						defaultColGroupDef={{ marryChildren: true }}
						frameworkComponents={{
							handleDetailsChangeRender: handleDetailsChangeRender,
							schema: customersFilterSchema,
							handleDateCellRenderer: handleDateCellRenderer,
							handleUserRoleCellRenderer: handleUserRoleCellRenderer,
							handleRegisteredcustomerCellRenderer: handleRegisteredcustomerCellRenderer,
							handleValueCellRenderer: handleValueCellRenderer,
							setPayloadSchema: setcustomersFilterSchema,
							setParentSchema: setCustomers,
							customFloatingFilter: CustomFloatingFilter,
							customFilter: CustomFilter
						}}
						rowSelection={"single"}
					>
						{/* {user &&
						user.role === ROLES.super_admin &&
						((user.first_name === "Sourish" && user.last_name === "Mukherjee") ||
							(user.first_name === "Anupam" && user.last_name === "Biswas") ||
							(user.first_name === "Rahul" && user.last_name === "Dutta") ||
							(user.first_name === "Suman" && user.last_name === "Dutta")) ? (
							<AgGridColumn
								field="group_number"
								headerName="Group Number"
								suppressSizeToFit
							></AgGridColumn>
						) : null} */}
						<AgGridColumn
							field="group_name"
							headerName="Group Name"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="store_name"
							headerName="Store Name"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="customer_id"
							headerName="Customer Id"
							suppressSizeToFit
							// checkboxSelection={true}
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleValueCellRenderer"
							// floatingFilterComponentParams={{
							// 	suppressFilterButton: true
							// }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="first_name"
							headerName="First Name"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="last_name"
							headerName="Last Name"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="phone"
							headerName="Phone Number"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="age_range"
							headerName="Age Range"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							// cellRenderer="handleDateCellRenderer"
							filter={false}
						></AgGridColumn>
						<AgGridColumn
							field="gender"
							headerName="Gender"
							suppressSizeToFit
							//floatingFilterComponentParams={{ suppressFilterButton: true }}
							floatingFilterComponent="customFloatingFilter"
							filter="customFilter"
							// onCellClicked={handleViewcustomersDetails}
							cellStyle={() => {
								return { cursor: "pointer" };
							}}
						></AgGridColumn>
						<AgGridColumn
							field="action"
							headerName="Action"
							cellRenderer="handleDetailsChangeRender"
							filter={false}
							suppressSizeToFit={true}
						></AgGridColumn>
					</AgGridReact>
				</div>
			</div>
			<div className="custom-pagination" id="custom-pagination">
				<CustomPagination pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
			</div>
		</div>
	);
};

export default Customers;
