import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { ImageContainerProps } from "../../../../../../../@types/components/imageConatinerProps.types";
import "./ImageContainer.css";
import CardWithStat from "../../../../../../shared/cards/cardWithStat/CardWithStat";

import PeopleIcon from "@material-ui/icons/People";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle";
import HeightIcon from "@material-ui/icons/Height";
import { defaultImage, stepIcon } from "../../../../../../../assets";
import StraightenIcon from "@material-ui/icons/Straighten";

const ImageContainer = ({
	side,
	backGroundColor,
	raw_image_top,
	raw_image_left,
	predicted_image_top,
	predicted_image_left
}: ImageContainerProps) => {
	return (
		<Paper
			elevation={2}
			style={{
				minHeight: "40vh",
				padding: "10px",
				borderRadius: "15px",
				paddingBottom: "3rem",
				backgroundColor: backGroundColor
			}}
		>
			<h2 style={{ color: "#003545" }}>{side} Feet</h2>
			<div className="feetImageContainer">
				<div>
					<h3 style={{ textAlign: "left", marginBottom: "1rem", paddingLeft: "15px" }}>Raw Images</h3>
					<div className="rawImageContainer">
						<div className="image-holder">
							{raw_image_top ? <img src={raw_image_top} /> : <img src={defaultImage} />}
							<p>{side} Top </p>
						</div>
						<div className="image-holder">
							{raw_image_left ? <img src={raw_image_left} /> : <img src={defaultImage} />}
							<p>{side} Side </p>
						</div>
					</div>
				</div>
				<div>
					<h3 style={{ textAlign: "left", marginBottom: "1rem", paddingLeft: "15px" }}>Predicted Images</h3>
					<div className="predictedImageContainer">
						<div className="image-holder">
							{predicted_image_top ? <img src={predicted_image_top} /> : <img src={defaultImage} />}
							<p>{side} Top </p>
						</div>
						<div className="image-holder">
							{predicted_image_left ? <img src={predicted_image_left} /> : <img src={defaultImage} />}
							<p>{side} Side </p>
						</div>
					</div>
				</div>
			</div>
		</Paper>
	);
};

export default ImageContainer;
