import React from "react";
import "./ConnectedList.css"; // Assuming you have a CSS file

const ConnectedList = ({ items }: { items: string[] }) => {
	return (
		<div className="connected-list-container">
			<div className="vertical-line"></div>
			<ul className="connected-list">
				{items.map((disease, index) => (
					<li key={index} className="connected-list-item">
						<span className="dot"></span>
						<span className="text">{disease}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default ConnectedList;
